import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import ListBuildings from "@components/buildings/List"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "buildings.getAll",
  query: 'deleteAttempt=0'
}

const ListBuildingsPage = (props) => (
  <>
    <Seo title="Buildings List" />
    <div className="main-wrapper">
      <Sidebar menuActive="buildings" />
      <ListBuildings
        addBtnText="Building"
        menu="Buildings" 
        parentPath="buildings" 
        pageType="List"
        additionalParams={AdditionalParams}/>
    </div>
  </>
)

export default ListBuildingsPage;
