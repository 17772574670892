import React from "react";
import Select from 'react-select';
import {DefaultSelectValue} from "../../utility/Form";

const SearchComp = (props) => {
    return (
        <>
            {/* <li className="list-inline-item mb-0">  
                <Select
                    //name="branch_name"
                   options={[
                        DefaultSelectValue,
                        { value: "For Sale", label: "For Sale" },
                        { value: "To Rent", label: "To Rent" }
                    ]}
                    placeholder="Property Type"
                    //classNamePrefix={"select-opt"}                                                
                    isSearchable={true}
                    isMulti={true}
                    onChange={(event) => props.handleSearchChange(event, 'building')}
                />
            </li> */}
            <li className="list-inline-item mb-0 ">  
                <input className="form-control"
                    name="bedroom"
                    placeholder="Beds"
                    onChange={(event) => props.handleTextChange(event, {type: 'int'})}/>
            </li>
            <li className="list-inline-item mb-0 ">  
                <input className="form-control"
                    name="_where[0][price_gt]"
                    placeholder="Min Price"
                    onChange={(event) => props.handleTextChange(event, {type: 'float'})}/>
            </li>
            <li className="list-inline-item mb-0 ">  
                <input className="form-control"
                    name="_where[1][price_lt]"
                    placeholder="Max Price"
                    onChange={(event) => props.handleTextChange(event, {type: 'float'})}/>
            </li>
        </>
    )
}

export default SearchComp;
